<template>
  <div>
    <v-app-bar color='primary' dense dark app clipped-left flat>
      <v-app-bar-nav-icon
        v-if='$vuetify.breakpoint.mobile'
        @click='toggleSidebar'
      ></v-app-bar-nav-icon>
      <v-toolbar-title class='mr-6'>
        <router-link :to="{ name: 'Home' }" class='white--text' style='text-decoration: none;'>STRATA<span class='info--text'>FOCUS</span></router-link>
      </v-toolbar-title>
      <template v-if='show_buttons'>
        <v-btn
          v-for='(l, index) in main_links' :key='index'
          :to='l.link'
          text
          small
          class='mr-2'
        >
          {{ l.title }}
        </v-btn>
      </template>
      <v-spacer></v-spacer>
      <v-btn
        v-if='show_buttons'
        small
        color='info'
      >
        Sign up
      </v-btn>
      <v-btn
        v-if='show_buttons'
        small
        text
        href='https://app.stratafocus.com'
        class='ml-2'
      >
        Login
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>

export default { 
  name: 'top-nav',
  data: () => ({
    brokerageID: 3,
    main_links: [
      {
        link: { name: 'About' },
        title: 'About'
      },
      {
        link: { name: 'Pricing' },
        title: 'Pricing'
      },
    ]
  }),
  computed: {
    show_buttons () {
      return !this.$vuetify.breakpoint.mobile
    }
  },
  methods: {
    toggleSidebar () {
      this.$store.dispatch('openDrawer')
    }
  }
}
</script>