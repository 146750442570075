<template>
  <v-row class='my-5'>
    <v-col class='text-center'>
      <h1 :class='text_class'>
        {{ text }}
        <span :class='highlight_text_class'>{{ highlight_text }}</span>
      </h1>
      <span :class='sub_text_class'>{{ subtext }}</span>
    </v-col>
    <v-col cols='12' class='text-center'>
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'section-header',
  props: {
    text: {
      required: true
    },
    highlight_text: {
      required: false
    },
    subtext: {
      required: false
    },
    dark: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    text_color () {
      return this.dark ? 'grey--text text--lighten-3' : 'primary--text'
    },
    text_class () {
      let c = 'font-weight-bold mb-0 pb-0'
      if (this.$vuetify.breakpoint.smAndDown) {
        c += ' text-h3'
      } else {
        c += ' text-h2'
      }
      return this.text_color + ' ' + c
    },
    highlight_text_class () {
      let c = 'font-weight-bold mb-0 pb-0'
      if (this.$vuetify.breakpoint.smAndDown) {
        c += ' text-h3'
      } else {
        c += ' text-h2'
      }
      return this.dark ? 'primary--text text--darken-1 ' + c : 'info--text ' + c
    },
    sub_text_class () {
      let c = ''
      if (this.$vuetify.breakpoint.smAndDown) {
        c += ' body-1'
      } else {
        c += ' text-h5'
      }
      return this.text_color + ' ' + c
    }
  }
}
</script>