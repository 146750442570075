import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


function initialState () {
  return {
    showDrawer: false,
    // mainnav: [],
    // subnav: [],
    main_links: [
        {
          title: 'Home',
          url: 'Home'
        }
    ],
    // user_links: []
  }
}


const state = initialState()

const actions = {
  openDrawer: function (context) {
    context.commit('changeDrawer', true)
  },
  // loadNav: function (context, mainnav) {
  //   context.commit('loadNav', mainnav)
  // },
  // loadSubNav: function (context, subnav) {
  //   context.commit('loadSubNav', subnav)
  // },
  // resetNav: function (context) {
  //   context.commit('resetNav')
  // },
  // setMainLinks: function (context) {
  //   context.commit('setMainLinks')
  // },
  // setUserLinks: function (context) {
  //   context.commit('setUserLinks')
  // }
  // loadMainLinks: function (context, subnav) {
  //   context.commit('loadMainLinks', subnav)
  // },
  // loadMainLinks: function (context, subnav) {
  //   context.commit('loadMainLinks', subnav)
  // }
}

const mutations = {
  resetNav (state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  // setMainLinks (state) {
  //   state.main_links = [
  //     {
  //       title: 'Home',
  //       link: { name: 'Home' },
  //     },
  //     {
  //       title: 'About',
  //       link: { name: 'About' },
  //     },
  //   ]
  // },
  changeDrawer (state, status) {
    state.showDrawer = status
    console.log('Drawer statues: ')
    console.log(state.showDrawer)
  },
  // loadNav (state, mainnav) {
  //   state.mainnav = mainnav
  // },
  // loadSubNav (state, subnav) {
  //   state.subnav = subnav
  // }
}

export default new Vuex.Store({
  state,
  actions,
  mutations
})
