<template>
  <div>
    <div class='landing_1'>
      <br><br>
      <v-container class='primary'>
        <div class='my-5 mx-5'>
          <span :class='header_class'>Manage your stratas with ease.</span>
          <br>
          <br>
          <div :class='sub_header_class'>
            Built in Victoria BC, 
            <span class='white--text'>STRATA</span><span class='info--text'>FOCUS</span> is a cloud-based platform that enables you to manage a portfolio of stratas with ease, efficiency, and accuracy.
          </div>
          <br>
          <v-btn depressed color='info'>Sign up Today</v-btn>
        </div>
      </v-container>
    </div>



    <!-- EASE SECTION -->

    <div class='grey lighten-3 py-6'>
      <v-container>
        <v-row class='py-6'>
          <v-col cols='12'>
            <section-header
              text='Manage with'
              highlight_text='ease.'
              subtext="Get a bird's eye view, or drill down for more detail."
            ></section-header>
            <v-divider class='my-6'></v-divider>
          </v-col>
        </v-row>

        <v-row class='py-6 my-6'>
          <v-col cols='12' md='5' :class='main_text_class'>
            <span :class='section_header_class()'>Strata Overview</span><br>
            <br>
            It's easy to get a high level view of any strata with the "Overview" page. Here you'll find the basic details of the strata, who's on 
            council, any active contracts (like insurance, landscapers, etc) as well as a list of all the strata lots. Choose an option from the side panel,
            or drill down into a specific strata lot by clicking on it from the list.<br>
            <br>
            Each strata lot has its own "overview" page, too. Easily see the current and past owners and tenants, parking assignments, unpaid invoices, and more. 
          </v-col>
          <v-col cols='12' md='2'></v-col>
          <v-col cols='12' md='5'>
              <img width='100%' :class='elevate' src="@/assets/imgs/strata_overview.jpg">
          </v-col>
        </v-row>
      </v-container>
    </div>



    <div class='grey lighten-2 py-6'>
      <v-container>
        <v-row class='py-6 my-6'>
          <v-col cols='12' md='5'>
              <img width='100%' :class='elevate' src="@/assets/imgs/unpaid_invoices.jpg">
          </v-col>
          <v-col cols='12' md='2'></v-col>
          <v-col cols='12' md='5' :class='main_text_class'>
            <span :class='section_header_class()'>Accounts Receivable</span><br>
            <br>
            Knowing who owes money to a strata or to your brokerage is critical. Strata Focus brings <strong>all</strong> unpaid invoices across all stratas and
            your brokerage to a single page. This allows you to easily see who owes money to whom.<br>
            <br>
            Click on any invoice row and you are taken to that invoice to see all its details or filter the list to see invoices for a specific strata.
            You can even see how much is owing and when the last reminder was sent to the payee. 

          </v-col>
        </v-row>
      </v-container>
    </div>

    <!--

    <div class='grey lighten-3 py-6'>
      <v-container>
        <v-row class='py-6 my-6'>
          <v-col cols='12' md='5' :class='main_text_class'>
            <span :class='section_header_class()'>Maintenance Schedule</span><br>
            <br>
            You can plan out the annual maintenance schedule on a single page, tweaking dates, assigning preferred vendors and adding anticipated costs. 
            Then generate a printout or PDF that can be circulated to the council or all owners so everyone is on the same page. 
          </v-col>
          <v-col cols='12' md='2'></v-col>
          <v-col cols='12' md='5'>
              <img width='100%' :class='elevate' src="@/assets/imgs/maintenance.jpg">
          </v-col>
        </v-row>
      </v-container>
    </div>
    -->

    <!-- END EASE SECTION -->



    <!-- EFFICIENT SECTION -->

    <div class='blue-grey darken-1 py-6'>
      <v-container>
        <v-row class='py-6'>
          <v-col cols='12'>
            <section-header
              dark
              text='Manage with'
              highlight_text='efficiency.'
              subtext="Routine tasks can be completed incredibly fast."
            ></section-header>
            <v-divider class='my-6'></v-divider>
          </v-col>
          <v-col cols='12' md='5' :class="[main_text_class] + ' grey--text text--lighten-2'">
            <span :class='section_header_class(true)'>Monthly Strata Fees</span><br>
            Strata Focus makes processing all your stratas' monthly strata fee payments extremely efficient. We've streamlined the process to be a <strong>one-click task</strong>. Yup, one click. <br>
            <br>
            With the click of a button on the strata fee generator, Strata Focus will create all the necessary invoices, journal entries, payments and deposits &mdash; everything. All with a single click of a button. <br>
            <br>
            Even with large stratas with hundreds of strata lots, it only takes minutes, not hours, to process the monthly strata fees.
          </v-col>
          <v-col cols='12' md='2'></v-col>
          <v-col cols='12' md='5'>
              <img width='100%' :class='elevate' src="@/assets/imgs/strata_fees_generator.jpg">
          </v-col>
        </v-row>
      </v-container>
    </div>



    <div class='blue-grey darken-2 py-6'>
      <v-container>
        <v-row class='py-6 my-6'>
          <v-col cols='12' md='5'>
              <img width='100%' :class='elevate' src="@/assets/imgs/agency_fees.jpg">
          </v-col>
          <v-col cols='12' md='2'></v-col>
          <v-col cols='12' md='5' :class="[main_text_class] + ' grey--text text--lighten-2'">
            <span :class='section_header_class(true)'>Agency Invoices</span><br>
            <br>
            When its time to send your monthly agency fee invoice to each strata, Strata Focus has another one-click task awaiting. With the click of a button, Strata Focus can generate all the invoices for your agency fees as receivables on your brokerage accounts. But it gets better, Strata Focus will also record these invoices as <strong>expenses on each strata's books</strong>.<br>
            <br>
            With one click, you can set up all the receivables and payables for all your stratas. That's a lot faster than posting each invoice to each strata one by one!<br>
            <br>
          </v-col>
        </v-row>
      </v-container>
    </div>


    <!-- END EFFICIENT SECTION -->






    <!-- ACCURACY SECTION -->

    <div class='grey lighten-3 py-6'>
      <v-container>
        <v-row class='py-6'>
          <v-col cols='12'>
            <section-header
              text='Manage with'
              highlight_text='accuracy.'
              subtext="Harness the power of the built in custom accounting system."
            ></section-header>
            <v-divider class='my-6'></v-divider>
          </v-col>
        </v-row>

        <v-row class='py-6 my-6'>
          <v-col cols='12' md='5' :class='main_text_class'>
            <span :class='section_header_class()'>Accounting for Stratas</span><br>
            <br>
            Strata Focus has always been focused on British Columbia stratas. This is most appearant in the robust, double entry accounting system. 
            Built from scratch, the accounting system in Strata Focus follows the requirements of the Strata Property Act.<br>
            <br>This means, for example, that the Operating Fund, the Contingency Reserve Fund, and any Special Levies are fully serparated with their own chart of accounts, balance sheet, income statement, and more.<br>
            <br>
            Not only will this make your financial reports much easier to read by councilors and owners, but it ensures compliance with the
            <a href='https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/98043_00'>Strata Property Act</a> and the 
            <a href='https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/43_2000'>Regulations</a>. 
          </v-col>
          <v-col cols='12' md='2'></v-col>
          <v-col cols='12' md='5'>
              <img width='100%' :class='elevate' src="@/assets/imgs/strata_overview.jpg">
          </v-col>
        </v-row>
      </v-container>
    </div>



    <div class='grey lighten-2 py-6'>
      <v-container>
        <v-row class='py-6 my-6'>
          <v-col cols='12' md='5'>
              <img width='100%' :class='elevate' src="@/assets/imgs/income_statement.jpg">
          </v-col>
          <v-col cols='12' md='2'></v-col>
          <v-col cols='12' md='5' :class='main_text_class'>
            <span :class='section_header_class()'>Financial Reporting</span><br>
            <br>
            With the accuracy and proper separation of funds, Stata Focus can generate wonderfully simply and easy to understand financials. Monthly financial packages, which include a
            whole host of necessary financial reports, can be generated with ease so you can report to your councils with confidence.<br>
            <br>
            Financial reports are only useful if the information can be understood. By having each fund separated, each fund gets its own balance sheet and income statement.
            This makes it vastly more understandble, enabling council and owners to see how special levy or contingency projects are going, or the strata's general financial activity.
          </v-col>
        </v-row>
      </v-container>
    </div>



    <!-- END ACCURACY SECTION -->



    <!-- AND MORE SECTION -->

    <div class='blue-grey darken-1 py-6'>
      <v-container>
        <v-row class='py-6'>
          <v-col cols='12'>
            <section-header
              dark
              text="And yes, there's"
              highlight_text='more.'
              subtext="This is just a taste."
            ></section-header>
            <v-divider class='my-6'></v-divider>
          </v-col>
          <v-col cols='12' md='6' :class="[main_text_class] + ' grey--text text--lighten-2'">
            <span :class='section_header_class(true)'>Many more features</span><br>
            There are many more features built into Strata Focus, such as:<br>
            <ul>
              <li>Annual maintenance lists</li>
              <li>Printing Cheques</li>
              <li>Simple bank reconciliations</li>
              <li>Creating tickets for staff</li>
              <li>Various printouts like AGM registrations, owner lists, tenent lists</li>
              <li>Special Levy creation with auto-calculation of owner invoices</li>
              <li>Quick creation, and easy tracking of, interfund loans for cash-crunches</li>
              <li>Form F and Form B creation with fast document request invoicing</li>
            </ul>
            <br>
            We're always looking to improve Strata Focus. So if you have a specific process you would like to see built, let us know &mdash; we'd love to hear your ideas! 
          </v-col>
          <v-col cols='12' md='6'>
              <img width='100%' :class='elevate' src="@/assets/imgs/stratas_list.jpg">
          </v-col>
        </v-row>
      </v-container>
    </div>


    <div class='primary py-6'>
      <v-container>
        <v-row class='py-6'>
          <v-col cols='12'>
            <section-header
              dark
              text="Sign up today!"
              highlight_text=''
              subtext="Manage your brokerage with ease, efficiency, and accuracy"
            >
            </section-header>
            <v-divider class='my-6'></v-divider>
          </v-col>
          <v-col cols='12' md='6' :class="[main_text_class] + ' grey--text text--lighten-2'">
            <span :class='section_header_class(true)'>Want to chat more about it?</span><br>
            If you have any questions, we'd be more than happy to have a chat!<br>
            <br>
            Fill in your email or phone, and we'll get back to you right away during the work day. 

          </v-col>
          <v-col cols='12' md='6'>
            <v-card :class='elevate'>
              <v-form v-model='isFormValid'>
                <v-card-text>
                  <v-text-field
                    v-model='email'
                    label='Email'
                    outlined
                    :rules='[email_check]'
                  ></v-text-field>
                  <v-text-field
                    v-model='phone'
                    label='Phone number'
                    outlined
                    :rules='[phone_check]'
                    persistent-hint
                    hint='Use format: 123-123-1234'
                  ></v-text-field>
                  <v-btn color='info'>
                    <v-icon left color='white'>mdi-email</v-icon>
                    Submit
                  </v-btn>
                </v-card-text>
                </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model='showPic' width='1185px'>
      <v-card>
        <v-card-text>
          {{ currentPic }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <sf-footer></sf-footer>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import SfFooter from '@/components/SfFooter'

export default {
  name: 'home-page',
  components: {
    SectionHeader,
    SfFooter
  },
  computed: {
    header_class () {
      let h_class = 'font-weight-bold white--text'
      h_class += this.$vuetify.breakpoint.smAndDown ? ' text-h3' : ' text-h1'
      return h_class
    },
    sub_header_class () {
      let sh_class = 'grey--text text--lighten-1'
      sh_class += this.$vuetify.breakpoint.smAndDown ? ' text-body-1' : ' text-h5'
      return sh_class
    },
    main_text_class () {
      return this.$vuetify.breakpoint.smAndDown ? ' text-body-2' : ' text-body-1'
    }
  },
  data: () => ({
    showPic: false,
    currentPic: null,
    elevate: 'elevation-24',
    isFormValid: false,
    email: null,
    phone: null
  }),
  methods: {
    section_header_class (dark) {
      let sh_class =  ''
      sh_class +=  dark ? 'grey--text text--lighten-3' : 'blue-grey--text text--darken-2'
      sh_class += this.$vuetify.breakpoint.smAndDown ? ' subtitle-2' : ' text-h4'
      return sh_class
    },
    openPic (pic) {
      this.currentPic = pic
      this.showPic = true
    },
    email_check (value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'Invalid e-mail.'
    },
    phone_check (value) {
      const pattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10}$/
      // const pattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/
      return pattern.test(value) || 'Invalid phone number - must be format 555-555-5555.'
    }
  }
}
</script>