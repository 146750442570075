<template>
  <div>
    <div class='pricing'></div>



    <!-- EASE SECTION -->

    <div class='grey lighten-3 py-6'>
      <v-container>
        <v-row class='py-6'>
          <v-col cols='12'>
            <section-header
              text="Straightfoward "
              highlight_text='pricing.'
            ></section-header>
            <v-divider class='my-6'></v-divider>
          </v-col>
        </v-row>

        <v-row class='py-6 my-6'>
          <v-col cols='12' md='5' :class='main_text_class'>
            Whether you are a self managed strata with a single strata to manage or a brokerage with 50 stratas, we keep the pricing simple. It doesn't matter if your strata has 5 strata lots or 500, the price per strata remains the same. 
          </v-col>
          <v-col cols='12' md='2'></v-col>
          <v-col cols='12' md='5'>
            <v-row>
              <v-col class='text-center' cols='12' md='6'>
                <v-card>
                  <v-card-text>
                    <span class='text-h3 black--text font-weight-bold'>$75</span><br>
                    <span class='text-h6 grey--text text--lighten-1'> / month</span><br>
                    <br>
                    <span class='primary--text'>for first strata</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class='text-center' cols='12' md='6'>
                <v-card>
                  <v-card-text>
                    <span class='text-h3 black--text font-weight-bold'>$30</span><br>
                    <span class='text-h6 grey--text text--lighten-1'> / month</span><br>
                    <br>
                    <span class='primary--text'>each additional strata</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <sf-footer></sf-footer>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import SfFooter from '@/components/SfFooter'

export default {
  name: 'pricing',
  components: { SectionHeader, SfFooter },
  data: () => ({
    card_title: 'text-h5 font-weight-bold primary--text'
  }),
  computed: {
    header_class () {
      let h_class = 'font-weight-bold white--text'
      h_class += this.$vuetify.breakpoint.smAndDown ? ' text-h3' : ' text-h1'
      return h_class
    },
    sub_header_class () {
      let sh_class = 'grey--text text--lighten-1'
      sh_class += this.$vuetify.breakpoint.smAndDown ? ' text-body-1' : ' text-h5'
      return sh_class
    },
    main_text_class () {
      return this.$vuetify.breakpoint.smAndDown ? ' text-body-2' : ' text-body-1'
    }
  },
  methods: {
    section_header_class (dark) {
      let sh_class =  ''
      sh_class +=  dark ? 'grey--text text--lighten-3' : 'blue-grey--text text--darken-2'
      sh_class += this.$vuetify.breakpoint.smAndDown ? ' subtitle-2' : ' text-h4'
      return sh_class
    },
  }
}
</script>
