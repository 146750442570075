<template>
  <v-navigation-drawer app clipped class='pt-3' color='grey lighten-5' :value.sync='$store.state.showDrawer'>

    <sf-nav-list
      v-if='$vuetify.breakpoint.mobile'
      title='Main Menu'
      :items='mobile_links'
    ></sf-nav-list>


  </v-navigation-drawer>
</template>

<script>
import SfNavList from '@/components/SfNavList'

export default {
  name: 'side-nav',
  components: {
    SfNavList
  },
  computed: {
    mobile_links () {
      return this.$store.state.main_links
    },
  },
}
</script>
