import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/home/Home'
import Pricing from '@/views/pricing/Pricing'
import About from '@/views/about/About'
import Features from '@/views/features/Features'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
]

const router = new VueRouter({
  routes
})

export default router
