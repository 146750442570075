<template>
  <v-app id='app'>
      <div>
      <top-bar></top-bar>
      <side-nav v-if='$vuetify.breakpoint.mobile'></side-nav>
      <v-main>
        <router-view/>
      </v-main>
    </div>
  </v-app>
</template>

<script>

import TopBar from '@/components/TopBar'
import SideNav from '@/components/SideNav'

export default {
  name: 'App',
  components: {
    TopBar, SideNav
  },
}
</script>