import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: '#114677',
        // secondary: '#00a6ed'
        primary: '#264653',
        info: '#018ADF',
        error: '#AB3428',
        warning: '#FAA916',
        success: '#5C924F',
        background: '#264653'
      }
    },
    light: true,
  }
})
