<template>
  <div>
    <v-list
      dense
      nav
      class='mt-0 pt-0'
    >
      <v-subheader  v-if='title || subtitle'>{{ title }}</v-subheader>
      <v-list-item-group color='info'>
        <template v-for='(i, index) in items'>

          <!-- If there are children (submenu) -->
          <v-list-group
            v-if='i.children'
            :key='index'
            no-action
            color='info'
          >
            <template v-slot:activator>
              <v-list-item-icon><v-icon :color='i.iconColor'>{{ i.icon }}</v-icon></v-list-item-icon>
              <v-list-item-title>{{ i.title }}</v-list-item-title>
            </template>

            <template v-for='(c, cIndex) in i.children'>
              <v-list-group
                v-if='c.children'
                :key='cIndex'
                no-action
                color='info'
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ c.title }}</v-list-item-title>
                </template>

                <template v-for='(d, dIndex) in c.children'>
                  <v-list-item :key='dIndex' link :to='d.link' @click='d.url ? openUrl(d.url) : null'>
                    <v-list-item-title>{{ d.title }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list-group>

              <v-list-item v-else :key='cIndex' link :to='c.link' @click="c.url ? openUrl(c.url) : null">
                <v-divider v-if='c.divider'></v-divider>
                <v-list-item-title v-else>{{ c.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>

          <!-- NO submenu -->
          <v-list-item v-else :key='index' link :to='i.link ? i.link : ""' @click="i.url ? openUrl(i.url) : null">
            <template v-if='i.divider'>
              <v-subheader>{{ i.title }}</v-subheader>
              <v-divider></v-divider>
            </template>
            <template v-else>
              <v-list-item-icon>
                <v-icon :color='i.iconColor'>{{ i.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ i.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>

        <slot></slot>
        
        <v-list-item v-if='return_link' link :to='return_link.link '>
          <v-list-item-icon>
            <v-icon :color='return_link.iconColor'>{{ return_link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ return_link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
/*
  Expects to receive an array in this format:
  [
    {
      icon: ''
      iconColor: ''
      title: 'Text for menu item',
      link: { <route object> } <--- OPTIONAL
      url: "http...." <--- will open in new tab
      children: [
        {
          same structure...
        }
      ]
    }
  ]
*/

export default {
  name: 'sf-nav-list',
  props: {
    title: {
      required: false
    },
    subtitle: {
      required: false
    },
    items: {
      required: false,
      type: Array
    },
    return_link: {
      required: false,
      type: Object
    }
  },
  methods: {
    openUrl (url) {
      window.open(url)
    }
  }
}
</script>
